import React from "react"
import Circle from "../assets/image/about/faded-circle.svg"
import Layout from "../components/layout"

import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"

function privacyPolicyPages({ pageContext }) {
  return (
    <MultiLangBody _key={pageContext.key}>
      <Layout>
        <div className={"flex justify-center"}>
          <div className="content pt-40 px-6 text-justify lg:text-left lg:px-30 xl:px-40 2xl:px-40 text text-2xl line-text">
            <div className="relative">
              <img src={Circle} className="absolute -top-28 -left-24" />
              <p className="text-4xl font-semibold blue-dark2 pb-10">
                <MultiLangField name={"terms_of_use_heading"}>
                  Privacy Policy
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"terms_&_condition"}>
                  Terms & Condition
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"maintainer"}>
                  This website is designed, updated and maintained by Unifiers
                  Social Ventures Pvt Ltd. Though all efforts have been made to
                  ensure the accuracy of the content on this website, the same
                  should not be construed as a statement of law or used for any
                  legal purposes. In case of any ambiguity or doubts, users are
                  advised to verify/check with Unifiers Social Ventures Pvt
                  Ltd., B-100, LGF, Sarvodya Enclave and to obtain appropriate
                  professional advice. By registering yourself as a user with
                  SkillsTrainer.in it will be deemed that you have read,
                  understood and agreed to fully abide by all the terms and
                  conditions of the website as contained herein.{" "}
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"right_to_use"}>
                  The right to use the content and services of the
                  SkillsTrainer.in is on permission basis as per the terms and
                  conditions contained herein. Except the usage of the content
                  and services during the registered period, the registered
                  users shall not have any right or title over the website or
                  any of its contents. Under no circumstances will Unifiers
                  Social Ventures Pvt Ltd. be liable for any expense, loss or
                  damage including, without limitation, indirect or
                  consequential loss or damage, or any expense, loss or damage
                  whatsoever arising from use, or loss of use, of data, arising
                  out of or in connection with the use of this website.
                  SkillsTrainer.in and Unifiers Social Ventures Pvt Ltd reserves
                  the right, in its sole discretion, to terminate or refuse all
                  or part of its services, for any reason without notice to you.{" "}
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"terms_and_condition"}>
                  These terms and conditions shall be governed by and construed
                  in accordance with the Indian Laws. Any dispute arising under
                  these terms and conditions shall be subject to the
                  jurisdiction of the courts of New Delhi, India. The
                  information posted on this website could include hypertext
                  links or pointers to information created and maintained by
                  non-Government / private organizations. SkillsTrainer.in is
                  providing these links and pointers solely for your information
                  and convenience. When you select a link to an outside website,
                  you are leaving the SkillsTrainer.in website and are subject
                  to the privacy and security policies of the owners/sponsors of
                  the outside website. Unifiers Social Ventures Pvt Ltd., does
                  not guarantee the availability of such linked pages at all
                  times.
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={""}>Privacy Policy</MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"general_use"}>
                  As a general rule, this website does not collect Personal
                  Information about you when you visit the site. You can
                  generally visit the site without revealing Personal
                  Information, unless you choose to provide such information as
                  in the case of Registering yourself as a registered user of
                  SkillsTrainer.in We will not identify users or their browsing
                  activities, except when a law enforcement agency may exercise
                  a warrant to inspect the service provider’s logs or as per any
                  prevalent Indian cyber laws. Your email address will only be
                  recorded if you choose to register yourself for using the
                  SkillsTrainer courses. Your email address will not be used for
                  any other purpose, and will not be disclosed, without your
                  consent. Though we may provide from time to time details of
                  the new courses available on SkillsTrainer.in.
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"copyright_policy"}>
                  Copyright Policy
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"information_and_courses"}>
                  All the information and courses on this website are sole
                  copyright of Unifiers Social Ventures Pvt Ltd. or its
                  empaneled/ registered content providers. All material
                  available on this website SkillsTrainer.in including but not
                  limited to its design, text, graphics, screen shots,
                  animations, files, videos and the selection and arrangement
                  thereof is protected by copyright laws. The contents of this
                  website may not be reproduced partially or fully, without due
                  permission from Unifiers Social Ventures Pvt Ltd. If referred
                  to as a part of another publication, the source must be
                  appropriately acknowledged. The contents of this website can
                  not be used in any misleading or objectionable context.{" "}
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"delivery_and_shipping"}>
                  Delivery and Shipping Policy
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={""}>
                  Delivery means the digital access/ download or the physical
                  delivery of subscribed/ purchased content to the learner/
                  user/ customers. The delivery/ access/ download or any
                  physical delivery shall be made only after 100% payment is
                  received from the learner/ user/ customer or as per the
                  prevailing scheme which will be mentioned on the
                  SkillsTrainer.in website and as per the mentioned delivery
                  schedule.
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"refund_and_cancellation"}>
                  Refund and Cancellation
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"courses_once_subscribed"}>
                  Please note that Courses once subscribed cannot be cancelled
                  and no refund to that effect can be made. Since
                  SkillsTrainer.in is offering non-tangible irrevocable goods we
                  do not issue refunds once any course/ courses is/are
                  subscribed. As a learner/ user/ customer you are responsible
                  for understanding that no refund or cancellation is possible
                  upon any subscription at our site.
                </MultiLangField>
              </p>
            </div>

            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"pricing_structure"}>
                  Product/Services and Pricing Structure
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"courses_and_material"}>
                  All the courses and material available on SkillsTrainer.in has
                  been listed on the website. All the courses/ material may not
                  be available all the time and SkillsTrainer.in/ Unifiers
                  Social Ventures Pvt Ltd does not guarantee availability at all
                  the time. Whenever a learner/ user/ customer orders any
                  material the same shall be given as per the pricing mentioned
                  at that time on the website and only the prevailing price
                  shall be charged. Any government taxes and delivery charges as
                  mentioned and applicable shall be charged extra. The pricing
                  mentioned for various products/ material/ courses are subject
                  to change without any prior intimation of any kind.{" "}
                </MultiLangField>
              </p>
            </div>
            <div className="my-10">
              <p className="blue-dark2 text-xl font-semibold">
                <MultiLangField name={"disclaimer"}>Disclaimer</MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"legal_sanctity"}>
                  The contents of this website are for information and learning
                  purposes only, and do not have any legal sanctity. We do not
                  assume any legal liability on the completeness, accuracy or
                  usefulness of the contents provided in this website. All the
                  effort has been made to ensure authenticity, accuracy and that
                  no material is plagiarized. If there is any inadvertent
                  resemblance to any other content, it may be immediately
                  brought to the notice of Unifiers Social Ventures Pvt Ltd on
                  011-26562422/ 41073077 and we shall immediately review the
                  content and take the required action. Some links may be
                  provided to other external sites in some of the courses/
                  documents. We do not take responsibility for the accuracy of
                  the contents in those sites. The hyperlink given to external
                  sites do not constitute an endorsement of information,
                  products or services offered by these sites.
                </MultiLangField>
              </p>
              <p className="text-sm leading-2">
                <br />
                <MultiLangField name={"best_efforts"}>
                  Despite our best efforts, we do not guarantee that the
                  documents in this site are free from infection by computer
                  viruses etc. We welcome your suggestions to improve this
                  website and request that error (if any) may kindly be brought
                  to our notice.Terms & Condition. This website is designed,
                  updated and maintained by Unifiers Social Ventures Pvt Ltd.
                  Though all efforts have been made to ensure the accuracy of
                  the content on this website, the same should not be construed
                  as a statement of law or used for any legal purposes. In case
                  of any ambiguity or doubts, users are advised to verify/check
                  with Unifiers Social Ventures Pvt Ltd., B-100, LGF, Sarvodya
                  Enclave and to obtain appropriate professional advice. By
                  registering yourself as a user with SkillsTrainer.in it will
                  be deemed that you have read, understood and agreed to fully
                  abide by all the terms and conditions of the website as
                  contained herein.
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"to_use_our_service"}>
                  The right to use the content and services of the
                  SkillsTrainer.ni is on permission basis as per the terms and
                  conditions contained herein. Except the usage of the content
                  and services during the registered period, the registered
                  users shall not have any right or title over the website or
                  any of its contents. Under no circumstances will Unifiers
                  Social Ventures Pvt Ltd. be liable for any expense, loss or
                  damage including, without limitation, indirect or
                  consequential loss or damage, or any expense, loss or damage
                  whatsoever arising from use, or loss of use, of data, arising
                  out of or in connection with the use of this website.
                  SkillsTrainer.in and Unifiers Social Ventures Pvt Ltd reserves
                  the right, in its sole discretion, to terminate or refuse all
                  or part of its services, for any reason without notice to you.
                </MultiLangField>
              </p>
              <br />
              <p className="text-sm leading-2">
                <MultiLangField name={"terms_decided_by_indian_law"}>
                  These terms and conditions shall be governed by and construed
                  in accordance with the Indian Laws. Any dispute arising under
                  these terms and conditions shall be subject to the
                  jurisdiction of the courts of New Delhi, India. The
                  information posted on this website could include hypertext
                  links or pointers to information created and maintained by
                  non-Government / private organizations. SkillsTrainer.in is
                  providing these links and pointers solely for your information
                  and convenience. When you select a link to an outside website,
                  you are leaving the SkillsTrainer.in website and are subject
                  to the privacy and security policies of the owners/sponsors of
                  the outside website. Unifiers Social Ventures Pvt Ltd., does
                  not guarantee the availability of such linked pages at all
                  times
                </MultiLangField>
              </p>{" "}
            </div>
          </div>
        </div>
      </Layout>
    </MultiLangBody>
  )
}

export default privacyPolicyPages
